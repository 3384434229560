
body {
    font-family: 'Avenir', 'AvenirNext', 'Helvetica', sans-serif;
    color: #161513;
    background-color: #fff;
  }
  
  .sp2-transperant-navbar{
      width: 100vw;
      height: 7vh;
      background-color: #211e1c98;
      padding-left: 7vw;
      position: fixed;
  }
  
  .sp2-Link-of-story{
      position: relative;
      text-decoration: none;
      color: #ffffff;
      font-size: 16px;
      top: 13px;
    }
  
    .sp2-Link-of-story:hover{
      text-decoration:
      underline solid rgb(246, 220, 170);
    }
  
    .sp2-Link-of-story .arrow-storypage svg {
      position: absolute;
      top: -2px ;
      margin-left: -6px;  
    }
  
  .sp2-bg-img-1{
    background-image: url(../image/bg-img-of-story.png);
     background-size: contain;
      height: 80vh;
      width: 100vw;
  }
  .sp2-bg-img-2{
      background-color: #252220;
      background-image: url(../image/bg-texture.png);
      background-repeat: repeat;
      background-size: cover;
      background-blend-mode: darken;
      min-height: 162vh;
      max-height: 180vh;
      width: 100vw;
  }
  @media (max-width:1400px) {

    .sp2-bg-img-2{
      background-color: #252220;
      background-image: url(../image/bg-texture.png);
      background-repeat: repeat;
      background-size: cover;
      background-blend-mode: darken;
      min-height: 235vh;
      max-height: 300vh;
      width: 100vw;
  }
    
  }
  .sp2-overlay-div{
      position: absolute;
      top: 28vh;
      left: 2vw;
      width: 95vw;
      height: auto;
      color: #000;
      background-color: white;
      padding-left: 15vw;
      padding-right: 16vw;
  }
  .sp2-title-story{  
      position: absolute;
      margin-top: 6vh;
      color: #707070;
      font-size:21.504px;
      line-height: 30.1056px;
      vertical-align: baseline;
      letter-spacing: normal;
      word-spacing: 0px;
      font-weight: 400;
       font-style: normal;
      font-variant: normal;
      text-transform: none;
      text-decoration: none solid rgb(49, 45, 42);
      text-align:start;
      text-indent: 0px;
  }
  .sp2-Storyheader-text {
      margin-top: 10.4vh;
      color: #000;
      font-size:32.256px;
      line-height:38.7072px;
      vertical-align:baseline;
      letter-spacing:normal;
      word-spacing:0px;
      font-weight:400;
      font-style:normal;
      font-variant:normal;
      text-transform:none;
      text-decoration:none solid rgb(22, 21, 19);
      text-align:start;
  
    }
  
  
  /* Icon div styles */
  .sp2-icon-div {
      display: flex;
      flex-direction: column; 
      align-items: center;
      gap: 20px; 
      position: absolute;
      right: 6vw;
      top: 12vh;
      
    }
    .sp2-sharelink {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background-color: transparent;
      border-radius: 8px;
      text-decoration: none;
      color: #333; 
      transition: background-color 0.3s ease;
    }
    
    /* Hover effect */
    .sp2-sharelink:hover {
      background-color: #bbb; 
    }
    
    /* SVG Icons */
    .sp2-sharelink svg {
      flex-shrink: 0;
      width: 28px;
      height: 28px;
    }
  .sp2-facebook{
      border-radius: 50%;
  }

    .sp2-special-text-para {
      font-size:16.128px;
      line-height:22.5792px;
      vertical-align:baseline;
      letter-spacing:normal;
      word-spacing:0px;
      font-weight:300;
      font-style:italic;
      font-variant:normal;
      text-transform:none;
      text-decoration:none solid rgb(22, 21, 19);
      text-align:start;
      text-indent:0px;
      color: #707070;
    }
    .sp2-strong-text{
      font-size:12px;
      line-height:15.0528px;
      vertical-align: baseline;
      letter-spacing: normal;
      word-spacing:0px;
      font-weight:700;
      font-style:normal;
      font-variant:normal;
      text-transform:none;
      text-decoration:none solid rgb(122, 116, 100);
      text-align:start;
      text-indent:0px;
      color: #7f7f7f;
    }
  
    .sp2-normal-text{
      font-size:14.336px;
      line-height:20.0704px;
      vertical-align: baseline;
      letter-spacing: normal;
      word-spacing: 0px;
      font-weight:400;
      font-style: normal;
      font-variant:normal;
      text-transform:none;
      text-decoration:none solid rgb(22, 21, 19);
      text-align:start;
      text-indent: 0px;
    }
    .sp2-heading-story{
      font-size:21.504px;
      line-height:25.8048px;
      vertical-align:baseline;
      letter-spacing:normal;
      word-spacing:0px;
      font-weight: 800;
      font-style: normal;
      font-variant: normal;
      text-transform: none;
      text-decoration: none solid rgb(22, 21, 19);
      text-align: start;
       text-indent:0px;
    }
    .sp2-para-and-img{
     display: flex;
     justify-content: space-between;
     width: 100%;
     gap: 20px;
    }
    .sp2-side-img-of-para{
      width: 50%;
      height: auto;
      background-image: url(../image/automation-featured-image-1.jpg);
      background-position: center;
      background-size: cover;
      margin-bottom: 20px;
    }
    .sp2-join-para{
      width: 31vw;
    }
  
  
  @media screen and (max-width: 768px) {
      
  .sp2-transperant-navbar{
   display: none;
  }
  
  .sp2-bg-img-1{
      height: 15vh;
  }
  .sp2-bg-img-2{
    display: none;
    height: 0vh;
  }
  .sp2-overlay-div{
      position: relative;
      top: -1vh;
      left: 0;
      width: 100vw;
      height: auto;
      background-color: white;
      padding-left: 3vw;
      padding-right: 3vw;
  }
  .sp2-title-story{  
      position: absolute;
      top: -10vh;
      color: #707070;
      font-size: 18px;
  }
  
  .sp2-Storyheader-text {
      font-size: 24px;
      font-weight: 600;
  }
  
  /* Icon div styles */
  .sp2-icon-div {
    display: flex;
    flex-direction: row; 
    align-items: center;
    gap: 20px; 
    margin-right: 55vw;
    margin-top: 20vh; 
    display: none;
  }
    .sp2-sharelink svg {
      width: 24px;
      height: 24px;
  }
    .sp2-special-text-para {
      font-size:14px;
    }
    .sp2-strong-text{
      font-size:12px;
    }
  
    .sp2-normal-text{
      font-size:14px;
      word-spacing: normal;
    }
    .sp2-heading-story{
      font-size:18px;
    }
    .sp2-para-and-img{
      flex-direction: column;
    }
    .sp2-side-img-of-para{
      width: 100%;
      height: 20vh;
    }
    .sp2-join-para{
      width: 100%;
    }
  
  
    }