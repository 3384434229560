.sec-nav-bar {
    background-color: #211E1C;
    padding: 10px 90px;
    height: 10vh;
    position: relative;
}


/* Header div */
.leadership-header-div { 
    background-color: #375965;
    padding-left: 5vw;
    width: 100vw;
    height: 45vh;
    color: white;
    padding: 10px 90px;
    background-image: url(../image/customer/91522.jpg);
    background-size:cover;
}
  .Link-of-leadership{
    position: relative;
    text-decoration: none;
    color: white;
    font-size: 16px;
  }

  .Link-of-leadership:hover{
    text-decoration:
    underline solid rgb(255, 255, 255);
  }

  .Link-of-leadership .arrow-leadership-page svg {
    position: absolute;
    top: -2px ;
    margin-left: -5px;  
  }
  
  .leadership-header-text {
    top: 25%;
    color: white;
    position: absolute;
    font-size:32px;
    line-height:38.7072px;
    vertical-align:baseline;
    letter-spacing:normal;
    word-spacing:0px;
    font-weight:400;
    font-style:normal;
    font-variant:normal;
    text-transform:none;
    text-decoration:none solid rgb(22, 21, 19);
    text-align:start;
    z-index: 2;
  }

  .employ-info {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; 
    gap: 25px;
    background-color: #ffffff; 
    height: auto;
    width: 100vw;
    padding-left: 6vw;
    padding-top: 13vh;
    padding-bottom: 4vh;
}

.employ-card {
    background-color: #ffffff; 
    border-radius: 8px; 
    width: 20vw;
    height: auto;
    flex-wrap: wrap; 
}

.image-of-employ {
    background-size: cover;
    background-position: center;
    border-radius: 8px;
    width: 100%;
    height: 30vh;
}

.info-of-employ {
    font-size: 16px; 
    color: #333;
    margin-top: 20px; 
    margin-bottom: 1vh;
}
.employ-name {
    font-size:17.92px;
    line-height:25.088px;
    font-weight:800;
    text-align:start;
}
.employ-name:hover {
    text-decoration:underline solid rgb(22, 21, 19);
}
.employ-details {
    font-size:14.336px;
    line-height:20.0704px;
    font-weight:400;
    text-align:start;
}
.employ-details:hover{
    text-decoration:underline solid rgb(22, 21, 19);
}
.redirect-link{
    color: inherit;
    text-decoration: none;
}
.redirect-link:hover{
    text-decoration:underline solid rgb(22, 21, 19);
}
.employ-read-page{
    font-size:14.336px;
    line-height: 20.0704px;
    font-weight:500;
    text-decoration:none;
    text-align:start;
    color: #006b8f;

}
.employ-read-page:hover{
    text-decoration:underline solid rgb(0, 107, 143);
}
.heading-of-employ-page{
    font-size:21.504px; 
    line-height:25.8048px;
    font-weight:800;
    position: absolute;
    margin-top: -6vh;
}

@media (max-width: 768px) {

    .sec-nav-bar {
    padding: 5px 7px; 
    height: 8vh;
    }
    .sec-nav-bar li.selected::after {
        content: "";
        position: absolute;
        bottom: -2px;
        left: 0;
        right: 0;
        height: 4px;
        background-color: #F7E0A1;
    }
    .leadership-header-div {
        width: 100vw;
        height: 20vh;
        padding: 0px 0px;
    }
    
    .info-of-employ {
        font-size: 16px; 
        color: #333;
        margin-top: 20px; 
        margin-bottom: 1vh;
        padding-top: 5vh;
    }
    .Link-of-leadership .arrow-leadership-page svg {
        display: none;
    }
    .Link-of-leadership {
        display: none;
    }
    .leadership-header-text {
        top: 20%;
        padding-left: 4vw;
    }
    .employ-info {
        display: flex;
        flex-direction: column;
        padding-left: 4vw;
        padding-right: 4vw;
       
    }
    .employ-card { 
        border-radius: 8px;
        width: 90vw;
        height: auto;
    }
}