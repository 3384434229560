
body {
    font-family: 'Avenir', 'AvenirNext', 'Helvetica', sans-serif;
    color: #161513;
    background-color: #fff;
  }
  
  .genralStory-transperant-navbar{
      width: 100vw;
      height: 7vh;
      background-color: #211e1c98;
      padding-left: 7vw;
      position: fixed;
  }
  
  .genralStory-Link-of-story{
      position: relative;
      text-decoration: none;
      color: #ffffff;
      font-size: 16px;
      top: 13px;
    }
  
    .genralStory-Link-of-story:hover{
      text-decoration:
      underline solid rgb(246, 220, 170);
    }
  
    .genralStory-Link-of-story .arrow-storypage svg {
      position: absolute;
      top: -2px ;
      margin-left: -6px;  
    }
  
  .genralStory-bg-img-1{
    background-image: url(../image/bg-img-of-story.png);
     background-size: contain;
      height: 80vh;
      width: 100vw;
  }

  .genralStory-bg-img-2{
      background-color: #252220;
      background-image: url(../image/bg-texture.png);
      background-repeat: repeat;
      background-size: cover;
      background-blend-mode: darken;
      height: 280vh;
      width: 100vw;
  }
  @media (max-width:1400px) {

    .genralStory-bg-img-2{
      height: 355vh;
      width: 100vw;
  }
    
  }
  .sp4-bg-img-2{
    background-color: #252220;
    background-image: url(../image/bg-texture.png);
    background-repeat: repeat;
    background-size: cover;
    background-blend-mode: darken;
    min-height: 88vh;
    max-height: 110vh;
    width: 100vw;
  }
  @media (max-width:1400px) {

    .sp4-bg-img-2{
      min-height: 123vh;
      max-height: 150vh;
      width: 100vw;
    }
    
  }
  .fourseason-genralStory-bg-img-2{
    background-color: #252220;
    background-image: url(../image/bg-texture.png);
    background-repeat: repeat;
    background-size: cover;
    background-blend-mode: darken;
    height: 262.5vh;
    width: 100vw;
  }
  .fourSeasonsStory-genralStory-bg-img-2{
    background-color: #252220;
    background-image: url(../image/bg-texture.png);
    background-repeat: repeat;
    background-size: cover;
    background-blend-mode: darken;
    min-height: 188vh;
    max-height: 250vh;
    width: 100vw;
  }
  .sp5-bg-img-2{
    background-color: #252220;
    background-image: url(../image/bg-texture.png);
    background-repeat: repeat;
    background-size: cover;
    background-blend-mode: darken;
    min-height: 108vh;
    max-height: 150vh;
    width: 100vw;
  }
  @media (max-width:1400px) {

    .sp5-bg-img-2{
      min-height: 200vh;
      max-height: 250vh;
    }
  }
  .sp6-bg-img-2{
    background-color: #252220;
    background-image: url(../image/bg-texture.png);
    background-repeat: repeat;
    background-size: cover;
    background-blend-mode: darken;
    min-height: 226vh;
    max-height: 300vh;
    width: 100vw;
  }
  @media (max-width:1400px) {

    .sp6-bg-img-2{
      min-height: 294vh;
      max-height: 350vh;
      width: 100vw;
    }
    
  }
  .sp7-bg-img-2{
    background-color: #252220;
    background-image: url(../image/bg-texture.png);
    background-repeat: repeat;
    background-size: cover;
    background-blend-mode: darken;
    min-height: 61vh;
    max-height: 70vh;
    width: 100vw;
  }
.Smi-genralStory-bg-img-2{
  background-color: #252220;
  background-image: url(../image/bg-texture.png);
  background-repeat: repeat;
  background-size: cover;
  background-blend-mode: darken;
  min-height: 182vh;
  max-height: 200vh;
  width: 100vw;
}
@media (max-width:1400px) {

  .Smi-genralStory-bg-img-2{
    min-height: 262vh;
    max-height: 300vh;
  }
  .fourSeasonsStory-genralStory-bg-img-2{
    min-height: 275vh;
    max-height: 300vh;
  }
  .sp7-bg-img-2{
    min-height: 105vh;
    max-height: 110vh;
  }
  
}

  .genralStory-overlay-div{
      position: absolute;
      top: 28vh;
      left: 2vw;
      width: 95vw;
      height: auto;
      color: #000;
      background-color: white;
      padding-left: 15vw;
      padding-right: 16vw;
  }
  .genralStory-title-story{  
      position: absolute;
      margin-top: 6vh;
      color: #707070;
      font-size:21.504px;
      line-height: 30.1056px;
      vertical-align: baseline;
      letter-spacing: normal;
      word-spacing: 0px;
      font-weight: 400;
       font-style: normal;
      font-variant: normal;
      text-transform: none;
      text-decoration: none solid rgb(49, 45, 42);
      text-align:start;
      text-indent: 0px;
  }
  .genralStory-Storyheader-text {
      margin-top: 10.4vh;
      color: #000;
      font-size:32.256px;
      line-height:38.7072px;
      vertical-align:baseline;
      letter-spacing:normal;
      word-spacing:0px;
      font-weight:400;
      font-style:normal;
      font-variant:normal;
      text-transform:none;
      text-decoration:none solid rgb(22, 21, 19);
      text-align:start;
  
    }
  
  
  /* Icon div styles */
  .genralStory-icon-div {
      display: flex;
      flex-direction: column; 
      align-items: center;
      gap: 20px; 
      position: absolute;
      right: 6vw;
      top: 12vh;
    }
    .sp4-icon-div {
      display: flex;
      flex-direction: column; 
      align-items: center;
      gap: 20px; 
      position: absolute;
      right: 6vw;
      top: 12vh;
    }
    .sp5-icon-div {
      display: flex;
      flex-direction: column; 
      align-items: center;
      gap: 20px; 
      position: absolute;
      right: 6vw;
      top: 12vh;
    }
    .sp6-icon-div {
      display: flex;
      flex-direction: column; 
      align-items: center;
      gap: 20px; 
      position: absolute;
      right: 6vw;
      top: 12vh;
    }
    .genralStory-sharelink {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background-color: transparent;
      border-radius: 8px;
      text-decoration: none;
      color: #333; 
      transition: background-color 0.3s ease;
    }
    
    /* Hover effect */
    .genralStory-sharelink:hover {
      background-color: #bbb; 
    }
    
    /* SVG Icons */
    .genralStory-sharelink svg {
      flex-shrink: 0;
      width: 28px;
      height: 28px;
    }
  .genralStory-facebook{
      border-radius: 50%;
  }

    .genralStory-special-text-para {
      font-size:16.128px;
      line-height:22.5792px;
      vertical-align:baseline;
      letter-spacing:normal;
      word-spacing:0px;
      font-weight:300;
      font-style:italic;
      font-variant:normal;
      text-transform:none;
      text-decoration:none solid rgb(22, 21, 19);
      text-align:start;
      text-indent:0px;
      color: #707070;
    }
    .genralStory-strong-text{
      font-size:12px;
      line-height:15.0528px;
      vertical-align: baseline;
      letter-spacing: normal;
      word-spacing:0px;
      font-weight:700;
      font-style:normal;
      font-variant:normal;
      text-transform:none;
      text-decoration:none solid rgb(122, 116, 100);
      text-align:start;
      text-indent:0px;
      color: #7f7f7f;
    }
  
    .genralStory-normal-text{
      font-size:14.336px;
      line-height:20.0704px;
      vertical-align: baseline;
      letter-spacing: normal;
      word-spacing: 0px;
      font-weight:400;
      font-style: normal;
      font-variant:normal;
      text-transform:none;
      text-decoration:none solid rgb(22, 21, 19);
      text-align:start;
      text-indent: 0px;
    }
    .genralStory-heading-story{
      font-size:21.504px;
      line-height:25.8048px;
      vertical-align:baseline;
      letter-spacing:normal;
      word-spacing:0px;
      font-weight: 800;
      font-style: normal;
      font-variant: normal;
      text-transform: none;
      text-decoration: none solid rgb(22, 21, 19);
      text-align: start;
       text-indent:0px;
    }
    .genralStory-para-and-img{
      display: flex;
      justify-content: space-between;
      width: 100%;
      gap: 20px;
    }
    .first-div-of-side-img-customer{
      width: 50%;
    }
    .genralStory-side-img-of-para{
      width: 50%;
      height: auto;
      background-image: url(../image/LATTICE\ LABS\ LOGOS.jpg);
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      margin-bottom: 20px;
      border: 1.5px solid #000;
    }
    .genralStory-side-img-of-para-of-4-season{
    width: 50%;
    height: auto;
    background-image: url(../image/four_seasons-bg-img.jpg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    }
    .genralStory-side-img-of-para-of-related{
      width: 50%;
      height: auto;
      background-image: url(../image/SMI-bg-img.jpg);
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
    @media (max-width:1400px) {

      .genralStory-side-img-of-para-of-4-season{
        width: 50%;
        height: auto;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
      }
      .genralStory-side-img-of-para-of-related{
        background-size: contain;
        background-repeat: no-repeat;
      }
      
    }
    .sp4-side-img-of-para{
        width: 50%;
        height: auto;
        background-image: url(../image/Lattice-labs-slider-bg.jpg);
        background-position: center;
        background-size: cover;
        margin-bottom: 20px;
    }
    @media (max-width:1400px) {

      .sp4-side-img-of-para{
        width: 50%;
        height: auto;
        background-repeat: no-repeat;
        background-size: contain;
      }
      
    }
    .sp5-side-img-of-para{
      width: 50%;
      height: auto;
      background-image: url(../image/Health-care-slider-bg-ig.jpg);
      background-position: center;
      background-size: cover;
    }
    @media (max-width:1400px) {

      .sp5-side-img-of-para{
        width: 50%;
        height: auto;
        background-image: url(../image/Health-care-slider-bg-ig.jpg);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
      }
      
    }
    .sp6-side-img-of-para{
    width: 50%;
    height: auto;
    background-image: url(../image/customer-story/business-person-futuristic-business-environment.jpg);
    background-position: center;
    background-size: cover;
    margin-bottom: 20px;
    }
    @media (max-width:1400px) {

      .sp6-side-img-of-para{
       width: 50%;
       height: auto;
       background-repeat: no-repeat;
      }
      
    }
    .sp7-side-img-of-para{
      width: 50%;
      height: auto;
      background-image: url(../image/slider-helth-care-img.jpg);
      background-position: center;
      background-size: contain;
      margin-bottom: 20px;
      background-repeat: no-repeat;
    }
    @media (max-width:1400px) {

      .sp7-side-img-of-para{
        width: 50%;
        height: auto;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
      }
      
    }
    .genralStory-join-para{
      width: 31vw;
    }
  
  
  @media screen and (max-width: 768px) {
      
  .genralStory-transperant-navbar{
   display: none;
  }
  
  .genralStory-bg-img-1{
      height: 15vh;
  }
  .genralStory-bg-img-2{
    display: none;
    height: 0vh;
  }
  .sp3-bg-img-2{
    display: none;
    height: 0vh;
  }
  .sp4-bg-img-2{
    display: none;
    height: 0vh;
  }
  .sp5-bg-img-2{
    display: none;
    height: 0vh;
  }
  .sp6-bg-img-2{
    display: none;
    height: 0vh;
  }
  .sp7-bg-img-2{
    display: none;
    height: 0vh;
  }
  .fourseason-genralStory-bg-img-2 {
    display: none;
    height: 0vh;
  }
  .Smi-genralStory-bg-img-2{
    display: none;
    height: 0vh;
  }
  .genralStory-overlay-div{
      position: relative;
      top: -1vh;
      left: 0;
      width: 100vw;
      height: auto;
      background-color: white;
      padding-left: 3vw;
      padding-right: 3vw;
  }
  .genralStory-title-story{  
      position: absolute;
      top: -10vh;
      color: #707070;
      font-size: 18px;
  }
  
  .genralStory-Storyheader-text {
      font-size: 24px;
      font-weight: 600;
  }
  
  /* Icon div styles */
  .genralStory-icon-div {
    display: flex;
    flex-direction: row; 
    align-items: center;
    gap: 20px; 
    margin-right: 55vw;
    margin-top: 20vh; 
    display: none;
  }
  .sp4-icon-div{
    display: flex;
    flex-direction: row; 
    align-items: center;
    gap: 20px; 
    margin-right: 53vw;
    margin-top: 13vh; 
    display: none;
  }
  .sp6-icon-div{
    display: flex;
    flex-direction: row; 
    align-items: center;
    gap: 20px; 
    margin-right: 55vw;
    margin-top: 24vh; 
    display: none;
  }
  .sp5-icon-div{
    display: flex;
    flex-direction: row; 
    align-items: center;
    gap: 20px; 
    margin-right: 53vw;
    display: none;
    margin-top: 58vh; 
  }
    .genralStory-sharelink svg {
      width: 24px;
      height: 24px;
  }
    .genralStory-special-text-para {
      font-size:14px;
    }
    .genralStory-strong-text{
      font-size:12px;
    }
  
    .genralStory-normal-text{
      font-size:14px;
      word-spacing: normal;
    }
    .genralStory-heading-story{
      font-size:18px;
    }
    .genralStory-para-and-img{
      display: flex;
      width: 100%;
      height: auto;
      flex-direction: column;
    }
    .first-div-of-side-img-customer{
      width: 100%;
    }
    .genralStory-side-img-of-para{
      width: 100%;
      height: 20vh;
      background-image: url(../image/LATTICE\ LABS\ LOGOS.jpg);
      background-position: center;
      background-size: cover;
      margin-bottom: 10px;

    }
    .sp4-side-img-of-para{
      width: 100%;
      height: 20vh;
      background-image: url(../image/Lattice-labs-slider-bg.jpg);
      background-position: center;
      background-size: cover;
      margin-bottom: 10px;
    }
    .sp5-side-img-of-para{
      width: 100%;
      height: 20vh;
      background-image: url(../image/Health-care-slider-bg-ig.jpg);
      background-position: center;
      background-size: cover;
      margin-bottom: 10px;
    }
    .sp6-side-img-of-para{
      width: 100%;
      height: 20vh;
      margin-bottom: 10px;
    }
    .sp7-side-img-of-para{
      width: 100%;
      height: 20vh;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      margin-bottom: 10px;
    }
    .genralStory-join-para{
      width: 100%;
    }
    .genralStory-side-img-of-para-of-related{
      width: 100%;
      height: 20vh;
      background-image: url(../image/SMI-bg-img.jpg);
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
    .genralStory-side-img-of-para-of-4-season{
      width: 100%;
      height: 20vh;
      background-image: url(../image/four_seasons-bg-img.jpg);
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      margin-bottom: 20px;
    }
    .fourSeasonsStory-genralStory-bg-img-2{
      display: none;  
      height: 0vh;
    }
  }