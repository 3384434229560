body {
  font-family: 'Avenir', 'AvenirNext', 'Helvetica', sans-serif;
  color: #161513;
  background-color: #fff;
}

.transperant-navbar {
  width: 100vw;
  height: 7vh;
  background-color: #211e1c98;
  padding-left: 7vw;
  position: fixed;
}

.Link-of-story {
  position: relative;
  text-decoration: none;
  color: #ffffff;
  font-size: 16px;
  top: 13px;
}

.Link-of-story:hover {
  text-decoration:
    underline solid rgb(246, 220, 170);
}

.Link-of-story .arrow-storypage svg {
  position: absolute;
  top: -2px;
  margin-left: -6px;
}

.bg-img-1 {
  background-image: url(../image/bg-img-of-story.png);
  background-size: contain;
  height: 80vh;
  width: 100vw;
}

.bg-img-2 {
  background-color: #252220;
  background-image: url('../image/bg-texture.png');
  background-repeat: repeat;
  background-size: cover;
  background-blend-mode: darken;
  min-height: 250vh;
  max-height: 300vh;
  width: 100vw;
}

@media (max-width:1400px) {

  .bg-img-2 {
    background-color: #252220;
    background-image: url('../image/bg-texture.png');
    background-repeat: repeat;
    background-size: cover;
    background-blend-mode: darken;
    min-height: 357vh;
    max-height: 450vh;
    width: 100vw;
  }

}

.overlay-div {
  position: absolute;
  top: 28vh;
  left: 2vw;
  width: 95vw;
  height: auto;
  color: #000;
  background-color: white;
  padding-left: 15vw;
  padding-right: 16vw;
}

.title-story {
  position: absolute;
  margin-top: 6vh;
  color: #707070;
  font-size: 21.504px;
  line-height: 30.1056px;
  vertical-align: baseline;
  letter-spacing: normal;
  word-spacing: 0px;
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  text-transform: none;
  text-decoration: none solid rgb(49, 45, 42);
  text-align: start;
  text-indent: 0px;
}

.Storyheader-text {
  margin-top: 10.4vh;
  color: #000;
  font-size: 32.256px;
  line-height: 38.7072px;
  vertical-align: baseline;
  letter-spacing: normal;
  word-spacing: 0px;
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  text-transform: none;
  text-decoration: none solid rgb(22, 21, 19);
  text-align: start;

}


/* Icon div styles */
.icon-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  position: absolute;
  right: 6vw;
  top: 12vh;
}

.sharelink {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: transparent;
  border-radius: 8px;
  text-decoration: none;
  color: #333;
  transition: background-color 0.3s ease;
}

/* Hover effect */
.sharelink:hover {
  background-color: #bbb;
}

/* SVG Icons */
.sharelink svg {
  flex-shrink: 0;
  width: 28px;
  height: 28px;
}

.facebook {
  border-radius: 50%;
}

.special-text-para {
  font-size: 16.128px;
  line-height: 22.5792px;
  vertical-align: baseline;
  letter-spacing: normal;
  word-spacing: 0px;
  font-weight: 300;
  font-style: italic;
  font-variant: normal;
  text-transform: none;
  text-decoration: none solid rgb(22, 21, 19);
  text-align: start;
  text-indent: 0px;
  color: #707070;
}

.strong-text {
  font-size: 12px;
  line-height: 15.0528px;
  vertical-align: baseline;
  letter-spacing: normal;
  word-spacing: 0px;
  font-weight: 700;
  font-style: normal;
  font-variant: normal;
  text-transform: none;
  text-decoration: none solid rgb(122, 116, 100);
  text-align: start;
  text-indent: 0px;
  color: #7f7f7f;
}

.normal-text {
  font-size: 14.336px;
  line-height: 20.0704px;
  vertical-align: baseline;
  letter-spacing: normal;
  word-spacing: 0px;
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  text-transform: none;
  text-decoration: none solid rgb(22, 21, 19);
  text-align: start;
  text-indent: 0px;
}

.heading-story {
  font-size: 21.504px;
  line-height: 25.8048px;
  vertical-align: baseline;
  letter-spacing: normal;
  word-spacing: 0px;
  font-weight: 800;
  font-style: normal;
  font-variant: normal;
  text-transform: none;
  text-decoration: none solid rgb(22, 21, 19);
  text-align: start;
  text-indent: 0px;
}

.para-and-img {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
}

.side-img-of-para {
  width: 50%;
  height: auto;
  background-image: url(../image/2020-Gartner-Magic-Quadrant.jpg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}


@media screen and (max-width: 768px) {

  .transperant-navbar {
    display: none;
  }

  .bg-img-1 {
    height: 15vh;
  }

  .bg-img-2 {
    display: none;
    height: 0vh;
  }

  .overlay-div {
    position: relative;
    top: -1vh;
    left: 0;
    width: 100vw;
    height: auto;
    background-color: white;
    padding-left: 3vw;
    padding-right: 3vw;
  }

  .title-story {
    position: absolute;
    top: -10vh;
    color: #707070;
    font-size: 18px;
  }

  .Storyheader-text {
    font-size: 24px;
    font-weight: 600;
  }

  /* Icon div styles */
  .icon-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    margin-right: 50vw;
    margin-top: 48vh;
    display: none;
  }

  .sharelink svg {
    width: 24px;
    height: 24px;
  }

  .special-text-para {
    font-size: 14px;
  }

  .strong-text {
    font-size: 12px;
  }

  .normal-text {
    font-size: 14px;
    word-spacing: normal;
  }

  .heading-story {
    font-size: 18px;
  }

  .para-and-img {
    flex-direction: column;
  }

  .side-img-of-para {
    width: 100%;
    height: 20vh;
    background-image: url(../image/2020-Gartner-Magic-Quadrant.jpg);
    background-position: center;
    background-size: contain;
    margin-bottom: 20px;
  }

}